* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  background-color: #f0f8ff;
}

.header {
  text-align: center;
  width: 100%;
}
.header h1 {
  font-size: 3rem;
  margin-top: 30px;
  letter-spacing: 2px;
  color: rgb(255, 116, 2);
}
.header p {
  font-size: 1rem;
  letter-spacing: 2px;
  margin-top: 1rem;
  color: rgb(255, 116, 2);
}
.search__box {
  margin: 0 auto;
  width: 30%;
  margin-top: 3rem;
  margin-bottom: 3rem;
  height: 6vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search__box input {
  width: 70%;
  height: 100%;
  font-size: 1.5rem;
  text-indent: 10px;
  border-radius: 30px;
  border: 0;
  outline: 0;
  border-bottom: 1px solid black;
}
.search__box button {
  width: 25%;
  height: 100%;
  border-radius: 30px;
  font-size: 1.5rem;
  color: rgb(255, 116, 2);
  transition: all 0.5s linear;
  border: 0;
  outline: 0;
  cursor: pointer;
}
.search__box button:hover {
  background-color: #f0f8ff;
  color: yellowgreen;
  border: 1px solid yellow;
}

.emoji__box{
  width: 80%;
  min-height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.emoji__item{
  width: 150px;
  padding: 10px;
  background-color: #fff;
  text-align: center;
  transition: all 0.2s linear;
  overflow: hidden;
}
.emoji__item h3{
  font-size: 3rem;
  margin-bottom: 0.5rem;
}


.emoji__item:hover{
  box-shadow: 10px 10px 20px grey;
  
}

@media only screen and (max-width:768px){
  .search__box {
    display: grid;
    grid-template-columns: 100%;
    width: 60%;
    margin-bottom: 5rem;
  }
  .search__box input {
    width: 100%;
    height: 7vh;
  }
  .search__box button {
    width: 100%;
    margin-top: 1rem;
  
  }
  .emoji__box{
    display: grid;
    grid-template-columns: repeat(2,1fr);
  }

}